// import { limit, onSnapshot, orderBy, query } from 'firebase/firestore';
// import { get } from 'lodash';
// import moment from 'moment';
import { query } from 'firebase/firestore';
import { add } from 'lodash';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import msal from 'src/functions/msal';

// import signalsRef from '...data/signals/collections/signals';
// import { testKeyId } from '...data/test';

//TODOcwf: remove this
/*
export { testQuery as queryFunc } from '../functions/query';

export const removeme = () => {
  console.log('hi');
  testQuery();
};
*/
//TODOcwf: end remove this

const colors = {
  cell: '#f5f5f5',
  critical: '#cc3300',
  warning: '#ffcc00',
  normal: '#339900'
};

const buttonStyle = {
  background: '#0056FB',
  border: 0,
  padding: '14px 23px',
  fontWeight: 'bold',
  color: 'white',
  borderRadius: 999
};

const App = () => {
  // const [signals, setSignals] = useState([]);
  const [responses, setResponses] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [identityUserId, setIdentityUserId] = useState('test');
  const [loading, setLoading] = useState(false);

  const createDfendInstance = () => {
    window.dfend = window.DFend(apiKey, {
      onInit: () => {
        setInitialized(true);
      }
    });
  };

  const push = async () => {
    setLoading(true);
    const signal = {
      identifiers: {
        userId: identityUserId
      },
      metadata: {
        demo: true
      }
    };
    const result = await window.dfend.push(signal);
    console.log(result);
    setResponses((r) => [result, ...r]);
    setLoading(false);
  };

  useEffect(() => {
    console.log(responses);
  }, [responses]);

  // useEffect(() => {
  //   const q = query(signalsRef, orderBy('_m.c', 'desc'), limit(10));
  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const _signals = [];
  //     querySnapshot.forEach((doc) => {
  //       _signals.push({ id: doc.id, ...doc.data() });
  //     });
  //     console.log('SIGNALS', _signals);
  //     setSignals(_signals);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  return (
    <div style={{ fontFamily: 'sans-serif' }}>
      <h1>
        <img style={{ height: 25 }} src="https://dashboard.dfend.app/logo/logo.svg" />{' '}
        DFend Demo Tool
      </h1>
      <h3>Config</h3>
      <p>
        Find your API keys under{' '}
        <a href="https://dashboard.dfend.app/dashboard/setup/api-keys">
          Dashboard &gt; Setup &gt; API Keys
        </a>
        .
      </p>
      <p>
        If you haven&apos;t already, create your test identity from terminal.
        <br />
        <code>
          {
            "curl -X POST https://api.dfend.app/identity -H 'Content-Type: application/json' -H 'x-api-key: SECRET_API_KEY' -d '{\"userId\":\"test\"}'"
          }
        </code>
      </p>
      <p>
        For more information, see the{' '}
        <a href="https://dfend.gitbook.io/documentation/developer-api/initial-test">
          <strong>Initial Test</strong>
        </a>{' '}
        section of our docs.
      </p>
      <hr />
      <p>
        <label>
          Publishable API Key
          <br />
          <br />
          <input value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
        </label>
        <br />
        <br />
        <button style={buttonStyle} onClick={createDfendInstance}>
          {initialized ? 'Ok' : 'Initialize DFend SDK'}
        </button>
      </p>
      <hr />
      <h3>Send Signal</h3>
      <p>
        <label>
          Identity&apos;s User ID for Signals
          <br />
          <br />
          <input
            value={identityUserId}
            onChange={(e) => setIdentityUserId(e.target.value)}
          />
        </label>
      </p>
      <p>
        <button style={buttonStyle} disabled={loading} onClick={push}>
          {loading ? 'Sending...' : `Push signal for user ID: ${identityUserId}`}
        </button>
      </p>
      <hr />
      <h3>Responses</h3>
      <p>
        View the signals in{' '}
        <a href="https://dashboard.dfend.app/dashboard/insights/signals">
          Dashboard &gt; Insights &gt; Signals
        </a>
        .
      </p>
      <div>
        {responses.map((response, i) => (
          <div key={i}>{JSON.stringify(response, null, 2)}</div>
        ))}
      </div>
      {/*
      <h1>{signals.length}</h1>

      <h3>Signals</h3>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>IP</th>
            <th>Country</th>
            <th>Time</th>
            <th>Impossible Travel</th>
            <th>IP Similarity</th>
          </tr>
        </thead>
        <tbody>
          {signals.map(signal => {
            const hasImpossibleTravel = signal.immediateResults?.find(r => get(r, 'type') === 'ImpossibleTravel');
            const hasIpSimilarity = signal.immediateResults?.find(r => get(r, 'type') === 'IpSimilarity');
            return (
              <tr key={signal.id}>
                <td style={{ background: colors.cell, padding: '4px 8px', margin: '2px' }}>{signal.data.type}</td>
                <td style={{ background: colors.cell, padding: '4px 8px', margin: '2px' }}>{signal.data.ip}</td>
                <td style={{ background: colors.cell, padding: '4px 8px', margin: '2px' }}>{signal.data.enrichment?.ipdata?.emoji_flag}</td>
                <td style={{ background: colors.cell, padding: '4px 8px', margin: '2px' }}>{signal._m.c && moment(signal._m.c.toDate()).fromNow()}</td>
                <td style={{ background: hasImpossibleTravel ? colors.critical : colors.normal, padding: '4px 8px', margin: '2px' }}>{hasImpossibleTravel ? 'fail' : 'ok'}</td>
                <td style={{ background: hasIpSimilarity ? colors.warning : colors.normal, padding: '4px 8px', margin: '2px' }}>{hasIpSimilarity ? 'fail' : 'ok'}</td>
              </tr>
            );
          }
          )}
        </tbody>
      </table> */}
    </div>
  );
};

const container = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container);
root.render(<App />);
